import React from 'react';
import styles from '../../styles/widget-styles.scss';

export const AppWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <section className={styles.widgetStyles} tabIndex={-1}>
      {children}
    </section>
  );
};
