import React from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { App } from './components/app/app';
import { AppWrapper } from './components/app-wrapper/app-wrapper';
import { TPAComponentsProvider } from 'wix-ui-tpa';
import { ThankYouWidgetProps } from './types';

const Widget: React.FC<WidgetProps<ThankYouWidgetProps>> = (props) => {
  const { isMobile, isRTL } = useEnvironment();
  return (
    <TPAComponentsProvider
      // eslint-disable-next-line react-hooks/rules-of-hooks
      value={React.useMemo(() => ({ mobile: isMobile, rtl: isRTL }), [isMobile, isRTL])}
    >
      <AppWrapper>
        <App {...props} />
      </AppWrapper>
    </TPAComponentsProvider>
  );
};

export default Widget;
