import { ISettingsColor } from '@wix/tpa-settings';
import parseColor from 'color';

export const applyOpacity = (c: ISettingsColor, o: number): ISettingsColor => {
  const color = parseColor(c.value);
  const originalOpacity = color.alpha();
  const newOpacity = originalOpacity * o;
  return {
    value: color.alpha(newOpacity).toString(),
    opacity: newOpacity,
    name: null,
  };
};
